import {
  Box,
  IconButton,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import useUserPermissions from '../Hooks/useUserPermissions';
import {
  ActorConfirmedIcon,
  ActorInvitedIcon,
  ActorRejectedIcon,
  ActorUnknownIcon,
} from 'eventrocket-commoncomponents';
import { useTranslation } from 'react-i18next';

type CrewListItemProps = {
  staffData: Parse.Object;
  deleteStaff: (staffId: string) => void;
  inviteEventMember: (staffMember: Parse.Object) => void;
};

export default function CrewListItem(props: CrewListItemProps) {
  const { staffData, deleteStaff, inviteEventMember } = props;
  const { hasPermission } = useUserPermissions();
  const theme = useTheme();
  const { t } = useTranslation('staff'); // Neue Übersetzungsdatei für Crew

  let invitationStateColor;
  let actorIcon;
  const invitationStatus = staffData?.get('invitationStatus');

  switch (invitationStatus) {
    case 'pending':
      invitationStateColor = theme.palette.warning.main;
      actorIcon = (
        <ActorInvitedIcon
          sx={{ color: invitationStateColor, fontSize: '1rem' }}
        />
      );
      break;
    case 'confirmed':
      invitationStateColor = theme.palette.success.main;
      actorIcon = (
        <ActorConfirmedIcon
          sx={{ color: invitationStateColor, fontSize: '1rem' }}
        />
      );
      break;
    case 'rejected':
      invitationStateColor = theme.palette.error.main;
      actorIcon = (
        <ActorRejectedIcon
          sx={{ color: invitationStateColor, fontSize: '1rem' }}
        />
      );
      break;
    default:
      invitationStateColor = theme.palette.grey[400];
      actorIcon = (
        <ActorUnknownIcon
          sx={{ color: invitationStateColor, fontSize: '1rem' }}
        />
      );
  }

  return (
    <ListItem sx={{ display: 'contents' }}>
      <Box
        sx={{
          gridColumn: '1',
          display: 'flex',
          alignItems: 'center',
          marginRight: '0',
        }}
      >
        {actorIcon}
      </Box>
      <Stack sx={{ gridColumn: '2' }}>
        <Typography variant="body1">{staffData.get('firstname')}</Typography>
        <Typography variant="caption">{staffData.get('email')}</Typography>
      </Stack>
      <Stack
        sx={{ gridColumn: '3', display: 'flex', justifyContent: 'center' }}
      >
        <Typography variant="body2">
          {staffData.get('roles')?.[0].includes('eventRead')
            ? t('ROLE_eventRead')
            : t('ROLE_eventManager')}{' '}
        </Typography>
      </Stack>
      {hasPermission({
        objPath: 'event.settingsMenu',
        operation: 'update',
      }) && (
        <Stack direction="row" sx={{ gridColumn: '4' }}>
          <IconButton onClick={() => deleteStaff(staffData.id)}>
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={() => inviteEventMember(staffData)}>
            <MailOutlinedIcon />
          </IconButton>
        </Stack>
      )}
    </ListItem>
  );
}
