import Parse from 'parse';
import useAuthStore from '../../store/authStore';

export default async function getSessionApi(sessionId: string) {
  // get the current users session token
  const sessionToken = useAuthStore.getState().user.sessionToken;

  const session: Parse.Object = await Parse.Cloud.run(
    'getSession',
    { sessionId: sessionId },
    { sessionToken: sessionToken },
  );

  const sessionJson = session.toJSON();

  console.log('###1234-ParseDataSession', session);
  console.log('###1234-jsonizedSessionData', sessionJson);

  return session;
}
