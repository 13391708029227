import { useParams } from 'react-router-dom';
import { useStaffQuery } from '../../Router/routes/staff';
import {
  Box,
  Button,
  IconButton,
  List,
  Modal,
  Paper,
  Typography,
  Stack,
  useTheme,
} from '@mui/material';
import CrewListItem from './CrewListItem';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteStaffApi, setNewStaffApi } from '../../api';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useState } from 'react';
import CrewNewMemberForm from './CrewNewMemberForm';
import { useFeedback } from '../Feedback/FeedbackContext';
import sendStaffInvitationApi, {
  sendStaffInvitationParams,
} from '../../api/staff/sendStaffInvitationApi';
import { useTranslation } from 'react-i18next';
import useUserPermissions from '../Hooks/useUserPermissions';

type CrewListProps = { data?: any };

export default function CrewList(props: CrewListProps) {
  const { masterEventId, eventId } = useParams();
  const theme = useTheme();
  const { t } = useTranslation(['event', 'staff']);
  const { hasPermission } = useUserPermissions();
  const { setFeedback } = useFeedback();

  const { data: staffData, status: staffDataStatus } = useStaffQuery(eventId!);

  const queryClient = useQueryClient();

  const mutateNewStaff = useMutation({
    mutationFn: (data: any) =>
      setNewStaffApi(
        data.eventId,
        data.name,
        data.roleName,
        data.email,
        data.appUrl,
      ),
    onSuccess: () => {
      setFeedback({ type: 'success', message: t('staff:ROLE_invite_success') });
      queryClient.invalidateQueries({
        queryKey: ['staff', 'list', eventId],
      });
    },
    onError: () => {
      setFeedback({ type: 'error', message: t('staff:ROLE_invite_error') });
    },
  });

  const addNewStaff = async (name: string, email: string, roleName: string) => {
    mutateNewStaff.mutate({
      eventId: { masterEventId: masterEventId!, eventId: eventId! },
      email: email,
      roleName: roleName,
      name: name,
      appUrl: process.env.REACT_APP_PARENT_PUBLIC_URL!,
    });
  };

  const mutateDeleteStaff = useMutation({
    mutationFn: (data: any) => deleteStaffApi(data.staffId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['staff', 'list', eventId],
      });
    },
  });

  const deleteStaff = (staffId: string) => {
    setFeedback({
      type: 'confirmAction',
      headline: t('DETAILS-FORM.SECTION.ACCESS.FEEDBACK.DELETE.headline'),
      message: t('DETAILS-FORM.SECTION.ACCESS.FEEDBACK.DELETE.message'),
      callback: () => {
        mutateDeleteStaff.mutate({ staffId: staffId });
      },
      confirmButtons: {
        cancel: t('DETAILS-FORM.SECTION.ACCESS.FEEDBACK.DELETE.BUTTON_cancel'),
        confirm: t(
          'DETAILS-FORM.SECTION.ACCESS.FEEDBACK.DELETE.BUTTON_confirm',
        ),
      },
    });
  };

  const inviteEventMemberMutation = useMutation({
    mutationFn: async (params: sendStaffInvitationParams) => {
      sendStaffInvitationApi(params);
    },
    onSuccess: () => {
      setFeedback({ type: 'success', message: t('staff:ROLE_invite_success') });
      queryClient.invalidateQueries({
        queryKey: ['staff', 'list', eventId],
      });
    },
    onError: () => {
      setFeedback({
        type: 'error',
        message: t('staff:ROLE_invite_error'),
      });
    },
  });

  const inviteEventMember = (crewMember: Parse.Object) => {
    const crewRole = crewMember?.get('roles')?.[0].includes('eventRead')
      ? t('staff:ROLE_eventRead')
      : t('staff:ROLE_eventManager');
    setFeedback({
      type: 'confirmAction',
      message: t('staff:ROLE_invite_confirmMessage', {
        crewMemberName: crewMember?.get('firstname'),
        crewRole: crewRole,
      }),
      confirmButtons: {
        cancel: t('staff:ROLE_invite_BUTTON_cancel'),
        confirm: t('staff:ROLE_invite_BUTTON_confirm'),
      },
      callback: async () => {
        inviteEventMemberMutation.mutate({
          staffId: crewMember.id,
          eventId: { masterEventId: masterEventId!, eventId: eventId! },
          appUrl: process.env.REACT_APP_PARENT_PUBLIC_URL!,
        });
      },
    });
  };

  const [newStaffModalOpen, setNewStaffModalOpen] = useState(false);
  const toggleNewStaffModal = () => {
    setNewStaffModalOpen(!newStaffModalOpen);
  };

  return (
    <>
      <Box width="100%">
        {staffDataStatus === 'success' && (
          <List
            sx={{
              display: 'grid',
              gridTemplateColumns: '16px minmax(auto, 1fr) 1fr 80px',
              gap: '8px',
            }}
          >
            {staffData.map((staff: Parse.Object) => (
              <CrewListItem
                key={'staff-' + staff.id}
                staffData={staff}
                deleteStaff={deleteStaff}
                inviteEventMember={inviteEventMember}
              />
            ))}
          </List>
        )}

        {hasPermission({
          objPath: 'event.settingsMenu',
          operation: 'update',
        }) && (
          <Box sx={{ mb: 2, textAlign: 'end' }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={toggleNewStaffModal}
              startIcon={<AddOutlinedIcon />}
            >
              {t('staff:ROLE_BUTTON_addMenu')}
            </Button>
          </Box>
        )}
      </Box>

      <Modal open={newStaffModalOpen} onClose={toggleNewStaffModal}>
        <Paper
          // elevation={8}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            minWidth: { xs: 'unset', sm: '360px' },
            maxWidth: { xs: 'unset', sm: '480px' },
            maxHeight: { xs: 'unset', sm: '80svh' },
            width: { xs: 'calc(100svw - 20px)', sm: '30svw' },
          }}
        >
          <Box sx={{ pt: '16px', px: '20px', position: 'relative' }}>
            <Typography variant="h4">
              {t('staff:ROLE_FORM_headline')}
            </Typography>
            <IconButton
              sx={{ position: 'absolute', top: 0, right: 0 }}
              onClick={toggleNewStaffModal}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>

          <CrewNewMemberForm
            addNewStaff={addNewStaff}
            closeModalHandler={toggleNewStaffModal}
          />
        </Paper>
      </Modal>
    </>
  );
}
