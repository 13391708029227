import {
  Box,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  actorDataTypes,
  mediaDataTypes,
  slotDataTypes,
  useSlotContext,
} from '../../Slots/Context/SlotContext';
import { useState } from 'react';

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ActorDetailsForm from './Form/ActorDetailsForm';
import ActorSlotlist from './ActorSlotlist';
import MediaList from '../../Media/MediaList';
import ActorVitaForm from './ActorVitaForm/ActorVitaForm';

export type ActorDetailsWidgetProps = {
  actorData: actorDataTypes;
};

export default function ActorDetailsWidget(props: ActorDetailsWidgetProps) {
  // props
  const { actorData } = props;

  // const actorsMedia = getActorsMedia();

  //
  const {
    deleteMedia,
    deleteMediaVersion,
    updateMediaFile,
    updateActor,
    sessionData,
    slotsData,
    getActorsMedia,
  } = useSlotContext();

  // theme
  const theme = useTheme();

  // media queries
  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('sm'),
  );

  // tab state
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Box sx={{ bgcolor: theme.palette.navigationBg.main }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="basic tabs example"
        >
          <Tab
            label="Akteur-Info"
            icon={<PersonOutlinedIcon />}
            {...a11yProps(0)}
          />
          <Tab
            label="Slots"
            icon={<DescriptionOutlinedIcon />}
            {...a11yProps(1)}
          />
          <Tab
            label="Zustimmungen"
            icon={<AssignmentTurnedInOutlinedIcon />}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabIndex} index={0}>
        <ActorDetailsForm actorData={actorData} updateActor={updateActor} />
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={1}>
        <ActorSlotlist actorData={actorData} />
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={2}>
        Zustimmungen
      </CustomTabPanel>
    </>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`actor-tabpanel-${index}`}
      aria-labelledby={`actor-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `actor-tab-${index}`,
    'aria-controls': `actor-tabpanel-${index}`,
  };
}
