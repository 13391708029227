import useAuthStore, { populateAuthStore } from '../store/authStore';
// import { populateConfigStore } from '../store/configStore';
import { ConnectionDataType } from '../App';
import { getUserRolesApi, loginUserApi } from '../api';

import axios from 'axios';
import { error } from 'console';
import validateTokenApi from '../api/user/validateTokenApi';

import Parse, { LiveQueryClient } from 'parse';
import useLiveUpdateStore from '../store/liveUpdateStore';

export type configTypes = {
  parseServerUrl: string | undefined;
  parseAppId: string | undefined;
  javascriptKey: string | undefined;
  theme: string | undefined;
};

// realized as promise for future async
export async function setConfig(
  ssoToken: string,
  authUser: Parse.User,
  eventId: string,
  startUpActions?: { [key: string]: any },
  connectionData?: ConnectionDataType,
) {
  return new Promise(async (resolve, reject) => {
    // no fallbacks here
    const configData: configTypes = {
      parseServerUrl: connectionData?.serverUrl,
      parseAppId: connectionData?.appId,
      javascriptKey: connectionData?.javascriptKey,
      theme: process.env.REACT_APP_THEME,
    };

    // set connection data to global store
    useAuthStore.setState({ connectionData: configData });

    // set unread messages to global store
    useAuthStore.setState({
      unreadMessages: authUser?.get('unreadMessages') ?? 0,
    });

    // init parse
    // !!! Check error handling at this point

    // // ### >
    const localParse = Parse.initialize(
      String(configData.parseAppId),
      String(configData.javascriptKey),
    );

    Parse.serverURL = String(configData.parseServerUrl);

    const authData = {
      authData: { eventRocket: { id: authUser.id, token: ssoToken } },
    };

    const installationIdKey =
      'Parse/' + process.env.REACT_APP_PARSE_APP_ID + '/installationId';

    // get the installation id for session handling server side
    const installationId = localStorage.getItem(installationIdKey);
    // !!! throw if installationId is not present???

    // used for preview window
    const session: any | null = localStorage.getItem('bs_EVENTSERVER_SESSION');

    // init the live query connection
    const client = new LiveQueryClient({
      applicationId: process.env.REACT_APP_PARSE_APP_ID!,
      serverURL: process.env.REACT_APP_PARSE_LIVEQUERYSERVER_URL!,
    });

    client.open();

    // store liveQueryClient to global store
    useLiveUpdateStore.setState({ liveQueryClient: client });

    // check access code
    if (session) {
      // try to using the token from storage
      const sessionValid = await validateTokenApi(
        JSON.parse(session).sessionToken,
      ).catch((e) => {
        console.error(e);
      });

      if (sessionValid) {
        useAuthStore.setState({ user: JSON.parse(session) });
        resolve(useAuthStore.getState().user);
        return;
      }
    }

    // if no session or session invalid -> login
    // signup / login sso user
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': configData.parseAppId!,
      'X-Parse-REST-API-Key': configData.javascriptKey!,
      'X-Parse-Revocable-Session': '1',
      // 'X-Parse-Installation-Id': Parse.applicationId,
      'X-Parse-Installation-Id': installationId!,
    };

    const response = await fetch(
      process.env.REACT_APP_PARSE_SERVER_URL + '/users',
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: headers,
        body: JSON.stringify(authData),
      },
    ).catch((e) => {
      console.error(e), 'error';
      throw new Error('Fehler beim Anmelden des Benuters am EventServer');
    }); // !!! show error feedback & redirect to main dashboard;

    const user = await response.json();

    console.log('###!!!§-userLogin', user);
    console.log('###!!!§-userLoginStore', useAuthStore.getState().user);

    // make sure login didn't return an error
    if (!user?.code) {
      useAuthStore.setState({ user: user });
      localStorage.setItem('bs_EVENTSERVER_SESSION', JSON.stringify(user));
      // add ouid to lokal user obj if not existing
      if (!user.ouid) {
        user.ouid = authUser.id;
      }

      resolve(user);
    }

    reject(user);
  });
}
