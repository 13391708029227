import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  CrewNewMemberFormData,
  crewNewMemberFormDataDafaults,
  crewNewMemberFormDataValSchema,
} from './CrewNewMemberFormValSchema';
import { Box, Button, Stack } from '@mui/material';
import { ErSelect, ErTextField } from 'eventrocket-commoncomponents';
import { getErrorMessage } from '../../util/formHelpers';
import { useTranslation } from 'react-i18next';

type CrewNewMemberFormProps = {
  addNewStaff: (name: string, email: string, roleName: string) => void;
  closeModalHandler?: () => void;
};

export default function CrewNewMemberForm(props: CrewNewMemberFormProps) {
  // props
  const { addNewStaff, closeModalHandler } = props;

  // i18n
  const { t } = useTranslation('staff');

  // react hook form
  const {
    control,
    setValue,
    getValues,
    formState: { errors, isDirty },
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(crewNewMemberFormDataValSchema),
    defaultValues: crewNewMemberFormDataDafaults,
  });

  const submitForm = (data: CrewNewMemberFormData) => {
    reset();
    addNewStaff(data.name, data.email, data.role);
    if (closeModalHandler) {
      closeModalHandler();
    }
  };

  return (
    <>
      <Stack sx={{ mt: 2 }}>
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <Box sx={{ px: '20px' }}>
            <ErTextField
              fullWidth
              variant="outlined"
              size="small"
              fieldLabel={t('ROLE_FORM_fieldLabel_name', 'Name')}
              fieldName="name"
              control={control}
              error={errors?.name && getErrorMessage(errors, 'name')}
              sx={{ mb: 2 }}
            />

            <ErTextField
              fullWidth
              variant="outlined"
              size="small"
              fieldLabel={t('ROLE_FORM_fieldLabel_email', 'E-Mail')}
              fieldName="email"
              control={control}
              sx={{ mb: 2 }}
              error={errors?.email && getErrorMessage(errors, 'email')}
            />

            <ErSelect
              fullWidth
              variant="outlined"
              size="small"
              fieldLabel={t('ROLE_FORM_fieldLabel_role', 'Role')}
              fieldName="role"
              control={control}
              error={errors?.role && getErrorMessage(errors, 'role')}
              menuItems={[
                {
                  label: t('ROLE_eventManager', 'Event Manager'),
                  value: 'eventManager',
                },
                {
                  label: t('ROLE_eventRead', 'Event Read'),
                  value: 'eventRead',
                },
              ]}
              sx={{ mb: 2 }}
            />
          </Box>

          <Box
            sx={{ width: '100%', textAlign: 'center', px: '10px', pb: '10px' }}
          >
            <Button
              fullWidth
              type="submit"
              variant="outlined"
              disabled={!isDirty}
            >
              {t('ROLE_FORM_button_save', 'Save')}
            </Button>
          </Box>
        </form>
      </Stack>
    </>
  );
}
