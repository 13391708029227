import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import {
  ActorDefaults,
  updateActorValSchema,
} from '../../ActorList/updateActorValidationSchema';
import ActorDetailsFormLayout from './ActorDetailsFormLayout';
import { UpdateActorDataTypes } from '../../ActorList/actorFormTypes';
import { actorDataTypes } from '../../../Slots/Context/SlotContext';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UpdateActorApiParamsTypes } from '../../../../api/actor/updateActorApi';
import { ErTextFieldWithSubmit } from 'eventrocket-commoncomponents';

import _FileUpload from '../../../GeneralUI/FormElements/FileUpload/_FileUpload';
import { getErrorMessage } from '../../../../util/formHelpers';
import useUserPermissions from '../../../Hooks/useUserPermissions';

type ActorDetailsFormProps = {
  actorData: actorDataTypes;
  updateActor: (
    data: UpdateActorApiParamsTypes,
    invalidateQuery?: boolean,
  ) => Promise<actorDataTypes>;
};

export default function ActorDetailsForm(props: ActorDetailsFormProps) {
  // props
  const { actorData, updateActor } = props;

  // nav params
  const { eventId, sessionId } = useParams();

  // permission handling
  const { hasPermission } = useUserPermissions();

  // form handling
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    reset,
  } = useForm<UpdateActorDataTypes>({
    // mode: 'onChange',
    defaultValues: ActorDefaults,
    resolver: yupResolver(updateActorValSchema),
  });

  const buildActorName = (
    firstname: string | undefined,
    lastname: string | undefined,
  ) => {
    let actorName = '';
    if (firstname) actorName += firstname;
    if (firstname && lastname) actorName += ' ';
    if (lastname) actorName += lastname;
    return actorName;
  };

  // actor form fields
  const [actorFields, setActorFields] = useState<{
    name: string;
    firstname?: string;
    lastname?: string;
    email: string;
    title: string;
    organisation: string;
    mobile: string;
    vita: string;
    photo: any[];
    displayActorInAgenda: boolean;
    displayActorInAgendaSlots: Parse.Pointer[];
  }>({
    name: buildActorName(
      actorData?.get('firstname'),
      actorData?.get('lastname'),
    ),
    email: actorData?.get('email'),
    title: actorData?.get('title'),
    mobile: actorData?.get('mobile') || '',
    vita: actorData?.get('vita') || '',
    photo: actorData?.get('photo') || [],
    organisation: actorData?.get('organisation'),
    displayActorInAgenda: actorData?.get('displayActorInAgenda'),
    displayActorInAgendaSlots:
      actorData?.get('displayActorInAgendaSlots') || [],
  });

  // save the actor name
  const saveActorFields = (actorFields: any) => {
    updateActor({
      actorId: actorData.id,
      data: actorFields,
      sessionId: sessionId,
      eventId: eventId,
    });

    setActorFields((af) => {
      return {
        ...af,
        ...actorFields,
        name: buildActorName(actorFields.firstname, actorFields.lastname),
      };
    });

    // setIsEditingActorDetailsId(null);
  };

  // check if field value changed
  const watchName = useWatch({ control, name: 'name' });
  const watchEmail = useWatch({ control, name: 'email' });
  const watchTitle = useWatch({ control, name: 'title' });
  const watchMobile = useWatch({ control, name: 'mobile' });
  const watchPhoto = useWatch({ control, name: 'photo' });
  const watchVita = useWatch({ control, name: 'vita' });
  const watchOrganisation = useWatch({ control, name: 'organisation' });

  const isFieldEditable = useCallback(
    (
      fieldName:
        | 'name'
        | 'email'
        | 'title'
        | 'organisation'
        | 'mobile'
        | 'vita',
    ) => {
      if (getValues(fieldName)?.trim() !== actorFields[fieldName]?.trim()) {
        return true;
      } else {
        return false;
      }
    },
    [
      watchName,
      watchEmail,
      watchTitle,
      watchOrganisation,
      watchMobile,
      watchVita,
      actorFields,
      // actorData,
    ],
  );

  // update the form after saving
  useEffect(() => {
    setActorFields({
      name: buildActorName(
        actorData.get('firstname'),
        actorData.get('lastname'),
      ),
      email: actorData?.get('email'),
      mobile: actorData?.get('mobile') || '',
      vita: actorData?.get('vita') || '',
      photo: actorData?.get('photo') || [],
      title: actorData?.get('title'),
      organisation: actorData?.get('organisation'),
      displayActorInAgenda: actorData?.get('displayActorInAgenda'),
      displayActorInAgendaSlots: actorData?.get('displayActorInAgendaSlots'),
    });
  }, [actorData]);

  useEffect(() => {
    let tmpActorName = actorFields.name;

    // if name changed build name from first & last name,
    // otherwise the name would be displayed wrong on first render
    if (actorFields?.firstname && actorFields.lastname) {
      tmpActorName = buildActorName(
        actorFields?.firstname,
        actorFields.lastname,
      );
    }

    reset({
      ...actorFields,
      name: tmpActorName,
    });
  }, [actorFields]);

  return (
    <>
      <form
        onSubmit={handleSubmit(saveActorFields)}
        noValidate
        style={{ width: '100%' }}
      >
        <ErTextFieldWithSubmit
          readOnly={
            !hasPermission({
              objPath: 'actors',
              operation: 'update',
            })
          }
          control={control}
          fieldLabel="Name"
          fieldName="name"
          size="small"
          variant="outlined"
          fullWidth
          editActive={isFieldEditable('name')}
          submitForm={() => handleSubmit(saveActorFields)()}
        />

        <ErTextFieldWithSubmit
          readOnly={
            !hasPermission({
              objPath: 'actors',
              operation: 'update',
            })
          }
          sx={{ mt: 2 }}
          control={control}
          fieldLabel="Title"
          fieldName="title"
          size="small"
          variant="outlined"
          fullWidth
          editActive={isFieldEditable('title')}
          submitForm={() => handleSubmit(saveActorFields)()}
        />

        <ErTextFieldWithSubmit
          readOnly={
            !hasPermission({
              objPath: 'actors',
              operation: 'update',
            })
          }
          sx={{ mt: 2 }}
          control={control}
          fieldLabel="Organisation"
          fieldName="organisation"
          size="small"
          variant="outlined"
          fullWidth
          editActive={isFieldEditable('organisation')}
          submitForm={() => handleSubmit(saveActorFields)()}
        />

        <ErTextFieldWithSubmit
          readOnly={
            !hasPermission({
              objPath: 'actors',
              operation: 'update',
            })
          }
          sx={{ mt: 2 }}
          control={control}
          fieldLabel="E-Mail"
          fieldName="email"
          size="small"
          variant="outlined"
          fullWidth
          editActive={isFieldEditable('email')}
          submitForm={() => handleSubmit(saveActorFields)()}
        />

        <ErTextFieldWithSubmit
          readOnly={
            !hasPermission({
              objPath: 'actors',
              operation: 'update',
            })
          }
          sx={{ mt: 2 }}
          control={control}
          fieldLabel="Mobil"
          fieldName="mobile"
          size="small"
          variant="outlined"
          fullWidth
          editActive={isFieldEditable('mobile')}
          submitForm={() => handleSubmit(saveActorFields)()}
        />

        <_FileUpload
          disabled={
            !hasPermission({
              objPath: 'actors',
              operation: 'update',
            })
          }
          sx={{ mt: 2 }}
          control={control}
          fieldLabel="Photo"
          fieldName="photo"
          setValue={setValue}
          defaultValue={actorFields.photo}
          error={errors.photo && getErrorMessage(errors, 'photo')}
          imageResizerSettings={{
            maxWidth: 512,
            maxHeight: 512,
            minWidth: 512,
            minHeight: 512,
            jpegQuality: 80,
          }}
          submitForm={() => handleSubmit(saveActorFields)()}
        />

        <ErTextFieldWithSubmit
          readOnly={
            !hasPermission({
              objPath: 'actors',
              operation: 'update',
            })
          }
          sx={{ mt: 2 }}
          control={control}
          fieldLabel="Vitae"
          fieldName="vita"
          size="small"
          variant="outlined"
          fullWidth
          multiline
          minRows={4}
          maxRows={6}
          editActive={isFieldEditable('vita')}
          submitForm={() => handleSubmit(saveActorFields)()}
        />
      </form>
    </>
  );
}
